.trade-container {
  margin-top: 24px;
}

.trade-container h5 {
  width: 100%;
  text-align: left;
  opacity: 0.3;
}
.trades-wrapper {
  text-align: center;
}
.trades-wrapper button {
  margin: 24px auto;
}

@media only screen and (min-width: 900px) {
  .trade-container {
    margin-top: 0;
  }
}

.trade-wrapper {
  padding: 8px;
  border-radius: 16px;
  height: 80px;
  margin-bottom: 8px;
  transition: 0.2s ease;
  position: relative;
  cursor: pointer;
}
.trade-wrapper:hover {
  background: rgba(255, 255, 255, 0.4);
  box-shadow: 0 16px 48px 0 rgba(31, 38, 135, 0.1);
}

.direction-wrap {
  height: 100%;
  /* width: 100%; */
  /* background: var(--primary-background); */
  border: 2px solid var(--primary-color);
  border-radius: 8px;
  aspect-ratio: 1;
  /* color: #27ae60; */
  /* box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 4px 4px rgba(0, 0, 0, 0.05); */
  /* box-shadow: 0px 2px 4px rgb(0 0 0 / 9%), 0px 5px 6px rgb(0 0 0 / 8%); */
}

.direction-wrap svg {
  width: 32px;
  height: 32px;
}

.trade-wrapper h4 {
  font-weight: 600;
  margin-bottom: -4px;
}
.trade-shot {
  height: 64px;
  width: 64px;
  aspect-ratio: 1;
  border-radius: 10px;
  background: var(--primary-background);

  margin-right: 16px;
  box-shadow: 0px 1px 2px rgb(0 0 0 / 10%), 0px 4px 4px rgb(0 0 0 / 5%);
}
.trade-shot svg {
  width: 32px;
}
.trade-indicator {
  position: absolute;
  top: 16px;
  left: 16px;

  width: 12px;
  height: 12px;
  padding: 4px;
  /* height: 56px; */
  display: block;
  box-sizing: border-box;
  /* background: #27ae60; */
  border-radius: 100%;
  box-shadow: 0px 1px 2px rgb(0 0 0 / 10%), 0px 4px 4px rgb(0 0 0 / 5%);
}

.trade-tex-wrap {
  text-align: right;
  text-align: left;
}
.trade-new-tag {
  position: absolute;
  top: 8px;
  right: 8px;
  padding: 2px 6px;
  background: #2f80ed;
  border-radius: 8px;
  font-size: 12px;
  color: white;
  box-shadow: 0px 1px 2px rgb(0 0 0 / 10%), 0px 4px 4px rgb(0 0 0 / 5%);
}

.landing-wrap {
  height: 100%;
  padding: 30% 24px 10%;
  /* box-sizing: border-box; */
  /* align-items: flex-start; */
}

@media only screen and (min-width: 900px) {
  .landing-wrap {
    height: Calc(100vh - 80px);
    padding: 16px 24px 104px;
    box-sizing: border-box;
    align-items: flex-start;
  }
}

/* @import url("https://rsms.me/inter/inter.css");
html {
  font-family: "Inter", sans-serif;
} */

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");
html,
body {
  font-family: "Roboto", sans-serif;
}

h1 {
  font-weight: 900;
  font-size: 38px;
  line-height: 1.05;
  margin: 0;
}
h2 {
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 1.45;
  margin: 0;
}
h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 1.3;
  margin: 0;
}
h4 {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.45;
  margin: 0;
}
h5 {
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 1.5;
  margin: 0;
}
h6 {
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
}
p {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.6;
  margin: 0;
}

a {
  font-weight: 400;
}

@media screen and (min-width: 650px) {
  h1 {
    font-size: 56px;
  }
  h2,
  h4 {
    font-size: 24px;
  }
  h3 {
    font-size: 40px;
  }
  h5,
  h6 {
    font-size: 20px;
  }
}
@media screen and (min-width: 1200px) {
  h1 {
    font-size: 64px;
  }
  h2,
  h4 {
    font-size: 26px;
  }
  h3 {
    font-size: 48px;
  }

  h5,
  h6 {
    font-size: 22px;
  }
}

@media screen and (min-width: 1700px) {
  h1 {
    font-size: 80px;
  }
  h2,
  h4 {
    font-size: 32px;
  }
  h3 {
    font-size: 56px;
  }

  h5,
  h6 {
    font-size: 24px;
  }
  p {
    font-size: 20px;
  }
}

@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

header {
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  z-index: 101;
  position: fixed;
  display: flex;
  align-items: center;
  flex-direction: column;
  transform: translateY(0);
  transition: transform 0.2s ease-in-out, background 0.3s ease,
    box-shadow 0.4s ease-in-out;
  /* background: var(--tertiary-background); */
}

header.header-shadow {
  background: var(--primary-background);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.09), 0px 10px 20px rgba(0, 0, 0, 0.12);
}
header.header-hidden {
  transform: translateY(-110%);
}
header.mobile-menue-active {
  background: var(--tertiary-background);
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.14);
  border-radius: 0 0 20px 20px;
}

header > * {
  width: 100%;
  padding: 0 16px;
  min-height: 56px;
}

header ul {
  list-style-type: none;
}

header li {
  padding: 8px 24px;
  text-decoration: none;
  font-weight: 600;
  transition: 0.15s ease;
}
header li:hover {
  opacity: 0.5;
}

.header-logo svg {
  height: 24px;
}

.logo-no-text,
.extra-button,
.primary-button,
.header-nav-wrap {
  position: relative;
  display: none;
}
.logo-text {
  display: block;
}

#mobile-menu-button svg {
  width: 32px;
  height: 32px;
}

.mobile-menu-wrap {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: none;
  animation: fadeIn ease 0.2s;
}
.mobile-menu-wrap.mobile-menue-active {
  display: block;
}

.mobile-menu {
  display: none;
  box-sizing: border-box;
  width: 100%;
  padding: 8px 8px 16px;
  animation: fade-in-down ease 0.2s;
}
header.mobile-menue-active .mobile-menu {
  display: block;
}
.mobile-menu ul {
  margin: 0 !important;
  padding: 4px 0 !important;
  border-top: 1px solid rgba(185, 202, 212, 0.5);
  border-bottom: 1px solid rgba(185, 202, 212, 0.5);
}
.mobile-menu ul li {
  width: 100%;
  padding: 16px 30px;
  box-sizing: border-box;
  text-align: left;
}
.mobile-menu ul li a {
  text-decoration: none;
  color: var(--secondary-font-color);
  text-align: left;
  font-size: 18px;
  font-weight: 500;
  transition: 0.15s ease;
}

.header-foot a button {
  width: Calc(100% - 32px);
  font-size: 18px;
  height: 48px;
  margin: auto;
}

.header-foot {
  padding: 16px 0 0 !important;
  /* background: var(--highlight); */
}
.header-foot .footer-social-links > *:not(:last-child) {
  margin-right: 16px !important;
}
.header-foot a {
  /* padding: 8px !important;
  background: #b9cad4 !important;
  border-radius: 10px !important; */
  width: 100%;
  font-size: 18px;
  height: 48px;
  margin: auto;
}

.header-foot svg {
  height: 28px !important;
  width: 28px !important;
}

.header-tem {
  width: 100%;
}
@media only screen and (min-width: 600px) {
  .header-nav-wrap {
    position: relative;
  }
  .header-button-wrap > * {
    margin-left: 24px;
  }
}
@media only screen and (min-width: 700px) {
  header {
    flex-direction: row;
    justify-content: center;
  }
  .header-tem {
    width: 30%;
  }
  .mobile-menu {
    display: none;
  }
  .header-nav-wrap,
  .primary-button {
    display: flex;
  }

  #mobile-menu-button {
    display: none;
  }
  .logo-text {
    display: none;
  }
  .logo-no-text {
    display: block;
  }
  .header-nav-underline {
    position: absolute;
    bottom: 0;
    left: 0;
    transition: 0.2s ease-in-out;
    margin-bottom: -28px;
  }
  .header-nav-underline.active-tab {
    /* margin-bottom: -18px; */
    margin-bottom: 0;
  }

  .header-nav-select {
    /* width: 8px;
    height: 8px;
    background: var(--tertiary-color);
    border: 2px solid var(--tertiary-color);
    border-radius: 4px; */

    width: 100%;
    height: 6px;
    background: var(--tertiary-color-light);
    border-radius: 4px;

    /* width: Calc(100% + 20px);
    height: 6px;
    margin: 0 -10px;
    background: var(--tertiary-color);
    border-radius: 4px 4px 0 0; */
  }
}

@media only screen and (min-width: 900px) {
  header > * {
    min-height: 80px;
  }
  .header-logo svg {
    height: 24px;
  }

  .logo-text {
    display: block;
  }
  .logo-no-text {
    display: none;
  }
  .header-nav-underline.active-tab {
    margin-bottom: -2px;
  }
}

@media only screen and (min-width: 1200px) {
  header > * {
    padding: 0 32px;
  }
  .extra-button {
    display: flex;
  }
}


.landing-wrap {
  height: 100%;
  padding: 30% 24px 10%;
  /* box-sizing: border-box; */
  /* align-items: flex-start; */
}

@media only screen and (min-width: 900px) {
  .landing-wrap {
    height: Calc(100vh - 80px);
    padding: 16px 24px 104px;
    box-sizing: border-box;
    align-items: flex-start;
  }
}

.landing-logo {
  /* width: 80%; */
  width: 100%;
  max-width: 800px;
}

.landing-button-wrap {
  flex-wrap: wrap;
  margin-top: 32px;
  grid-row-gap: 8px;
  row-gap: 8px;
}

.landing-button-wrap button {
  height: 42px;
}
.landing-button-wrap > *:first-child {
  margin-right: 8px;
}

@media only screen and (min-width: 900px) {
  .landing-button-wrap {
    margin-top: 56px;
  }
  .landing-button-wrap button {
    height: 56px;
    padding: 10px 32px;
    font-size: 18px;
  }
  .landing-button-wrap > *:first-child {
    margin-right: 16px;
  }
}

@media only screen and (min-width: 900px) {
  .account-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
    height: 100%;
  }
}
@media only screen and (min-width: 1200px) {
  .account-wrapper {
    grid-template-columns: 2fr 1fr;
    grid-gap: 32px;
  }
}

.chart-container {
  padding: 8px;
  box-sizing: border-box;
  border-radius: 20px;
  min-height: 30vh;
  /* grid-gap: 16px; */
  grid-gap: 8px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, 1fr);
}
.chart-container .chart-wrapper {
  grid-row: 1/3;
}
.chart-items-container {
  display: grid;
  /* grid-gap: 16px; */
  grid-gap: 8px;
  grid-template-columns: repeat(3, 1fr);
}

@media only screen and (min-width: 600px) {
  .chart-container {
    grid-template-rows: 1fr 1fr 120px;
  }
  .chart-items-container {
    display: flex;
    align-items: center;
  }
}

.chart-item-wrapper {
  text-align: center;
  border-radius: 14px;
  aspect-ratio: 1;
  padding: 12px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 4px 4px rgba(0, 0, 0, 0.05);
  background: var(--primary-background);
  overflow: hidden;
}
/* Charts ---------------- */

/* .chart-item-chart-wrap {
  position: relative;
  aspect-ratio: 1;
  height: 100%;
  border-radius: 100%;
  background: #6fcf97;
  padding: 8px;
}

.chart-item-chart-inner {
  height: 100%;
  width: 100%;
  background: var(--primary-background);
  border-radius: 100%;
} */

/* .gauge-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 80px;
}
.gauge-wrapper svg {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
} */

/* ---------------- */
.chart-item-wrapper h2 {
  font-weight: 700;
  margin-bottom: -2px;
}
.chart-item-wrapper h6 {
  font-weight: 700;
  opacity: 0.3;
}

@media only screen and (min-width: 600px) {
  .chart-item-wrapper {
    height: 120px;
    width: 120px;
  }
}

.gauge-container {
  position: relative;
  width: 100%;
  max-width: 64px;
}

.gauge-container h5 {
  position: absolute;
  text-align: center;
  width: 100%;
  top: 30%;
  color: var(--primary-font);
}
.gauge-container p {
  font-size: 13px;
  font-weight: 500;
  opacity: 0.3;
  text-align: center;
  width: 100%;
  margin: 0;
  position: absolute;
  bottom: -10px;
  color: var(--primary-font);
}

.gauge-container svg {
  position: relative;
  z-index: 9;
}

.marker-wrapper {
  position: absolute;
  top: 0;
  left: Calc(50% - 2px);
  height: 64px;
  margin-bottom: 70px;
  z-index: 10;
}

.marker-line {
  border-radius: 2px;
  margin-top: -6px;
  width: 4px;
  height: 16px;
  background: white;
  box-shadow: 0px 1px 2px rgb(0 0 0 / 10%), 0px 4px 4px rgb(0 0 0 / 5%);
}

.chart-wrapper {
  /* border: 1px solid white; */
  /* border-radius: 14px; */
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

#chart {
  width: 100%;
  z-index: 9;
  margin: 12px 0 -12px;
}
.apexcharts-yaxis {
  display: block;
  margin-left: -8px;
}

.chart-buttons {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;
  height: 36px;
  overflow: visible;
}
.chart-buttons span {
  font-weight: 900;
  font-size: 26px;
  color: var(--highlight);
}
.chart-buttons .icon-button {
  margin-left: 6px;
}
span.chart-cents {
  font-size: 18px;
  opacity: 0.3;
  margin: 2px 0 0 2px;
  font-weight: 600;
}
span.chart-dollar {
  /* font-size: 18px; */
  opacity: 0.3;
  font-weight: 600;
  margin-right: 2px;
}

.trade-container {
  margin-top: 24px;
}

.trade-container h5 {
  width: 100%;
  text-align: left;
  opacity: 0.3;
}
.trades-wrapper {
  text-align: center;
}
.trades-wrapper button {
  margin: 24px auto;
}

@media only screen and (min-width: 900px) {
  .trade-container {
    margin-top: 0;
  }
}

.trade-wrapper {
  padding: 8px;
  border-radius: 16px;
  height: 80px;
  margin-bottom: 8px;
  transition: 0.2s ease;
  position: relative;
  cursor: pointer;
}
.trade-wrapper:hover {
  background: rgba(255, 255, 255, 0.4);
  box-shadow: 0 16px 48px 0 rgba(31, 38, 135, 0.1);
}

.direction-wrap {
  height: 100%;
  /* width: 100%; */
  /* background: var(--primary-background); */
  border: 2px solid var(--primary-color);
  border-radius: 8px;
  aspect-ratio: 1;
  /* color: #27ae60; */
  /* box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 4px 4px rgba(0, 0, 0, 0.05); */
  /* box-shadow: 0px 2px 4px rgb(0 0 0 / 9%), 0px 5px 6px rgb(0 0 0 / 8%); */
}

.direction-wrap svg {
  width: 32px;
  height: 32px;
}

.trade-wrapper h4 {
  font-weight: 600;
  margin-bottom: -4px;
}
.trade-shot {
  height: 64px;
  width: 64px;
  aspect-ratio: 1;
  border-radius: 10px;
  background: var(--primary-background);

  margin-right: 16px;
  box-shadow: 0px 1px 2px rgb(0 0 0 / 10%), 0px 4px 4px rgb(0 0 0 / 5%);
}
.trade-shot svg {
  width: 32px;
}
.trade-indicator {
  position: absolute;
  top: 16px;
  left: 16px;

  width: 12px;
  height: 12px;
  padding: 4px;
  /* height: 56px; */
  display: block;
  box-sizing: border-box;
  /* background: #27ae60; */
  border-radius: 100%;
  box-shadow: 0px 1px 2px rgb(0 0 0 / 10%), 0px 4px 4px rgb(0 0 0 / 5%);
}

.trade-tex-wrap {
  text-align: right;
  text-align: left;
}
.trade-new-tag {
  position: absolute;
  top: 8px;
  right: 8px;
  padding: 2px 6px;
  background: #2f80ed;
  border-radius: 8px;
  font-size: 12px;
  color: white;
  box-shadow: 0px 1px 2px rgb(0 0 0 / 10%), 0px 4px 4px rgb(0 0 0 / 5%);
}


.auth-modal-header {
  border-bottom: 1px solid var(--line-color);
}
.auth-modal-header h5 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 8px 16px 16px;
  box-sizing: border-box;
  cursor: pointer;
  color: var(--disabled-font-color);
}

.auth-header-selection {
  color: var(--highlight) !important;
}

.auth-modal-selection-line {
  margin: -4px 16px 0;
  position: relative;
  width: Calc(50% - 32px);
  transition: 0.2s ease-in-out;
  border-bottom: 4px solid var(--highlight);
}

.auth-modal-submit-wrapper button {
  width: 50%;
  margin-top: 12px;
}

.auth-modal-social-button {
  padding: 8px;
  border-radius: 10px;
  width: calc(50% - 8px);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.15s ease;
  cursor: pointer;
  font-weight: 400;
}
.auth-modal-social-button svg {
  margin-right: 8px;
}

.auth-password-strength-hint {
  font-size: 12px;
  color: var(--disabled-font-color);
  height: 0;
  box-sizing: border-box;
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.2s ease-in,
    height 0.15s cubic-bezier(0.34, 1.56, 0.64, 1);
}

/* opacity .5s cubic-bezier(.34,1.56,.64,1),transform .5s cubic-bezier(.34,1.56,.64,1),-webkit-transform .5s cubic-bezier(.34,1.56,.64,1); */

.auth-password-strength-hint-active {
  height: 48px;
  opacity: 1;
  padding-bottom: 8px;
}

.auth-password-strength-hint p {
  color: var(--primary-font);
}

.auth-password-check-note div {
  font-size: 14px;
  font-weight: 600;
  text-align: right;
  width: auto;
  pointer-events: none;
  color: rgb(249, 41, 83);
  padding: 0 12px 0 0;
  position: absolute;
  right: 0;
  height: 40px;
  display: flex;
  align-items: center;
}
.auth-password-check-note input {
  width: 100%;
}

.auth-error-message {
  font-size: 12px;
  color: rgb(249, 41, 83);
  padding-bottom: 8px;
}

.auth-forgot-botton {
  min-width: 80px;
}

.spinner {
  animation: rotate 2s linear infinite;
}
.spinner .path {
  stroke-linecap: round;
  animation: dash 0.75s ease-in-out infinite, colors 6s infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}


#footer {
  background: var(--tertiary-background);
}
.footer-brand {
  max-width: 280px;
}
.footer-content > * {
  flex-direction: column;
  align-items: center;
}
.footer-content > * div {
  width: 100%;
  align-items: center;
}

.footer-brand {
  margin-bottom: 24px;
}

.footer-brand svg {
  height: 48px;
  width: 48px;
  margin-bottom: 16px;
}

.footer-content-section p {
  display: flex;
  align-items: center;
  height: 32px;
  margin-bottom: 24px;
}
.footer-content-section a {
  height: 42px;
  transition: 0.15s ease;
}
.footer-content-section a:hover {
  color: var(--primary-font);
}

footer {
  background: black;
  padding-top: 0 !important;
  color: white;
  overflow: hidden;
}
footer > * {
  grid-gap: 16px;
  gap: 16px;
  flex-direction: column-reverse;
  text-align: center;
}
.footer-social-piller,
.footer-piller,
.footer-com {
  display: none;
}

.footer-social-links > *:not(:last-child) {
  margin-right: 8px;
}

.footer-social-links a {
  transition: 0.15s;
  background: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 6px;
  display: block;
  box-sizing: border-box;
}
.footer-social-links a:hover {
  background: rgba(255, 255, 255, 0.1);
}
.footer-social-links a svg {
  display: block;
  width: 24px;
  color: white;
  height: 24px;
}
footer > * div:first-of-type {
  font-size: 14px;
}

@media only screen and (min-width: 600px) {
  .footer-content > * {
    grid-gap: 32px;
    gap: 32px;
    flex-direction: row;
    align-items: flex-start;
    padding-bottom: 24px;
  }
  .footer-content > * div {
    text-align: left;
    align-items: flex-start;
  }

  .footer-brand {
    margin-bottom: 0;
  }
  .footer-brand svg {
    margin-bottom: 24px;
  }
  .footer-content-sections-wrapper {
    grid-gap: 48px;
    gap: 48px;
    width: 100%;
    max-width: 400px;
  }
  .footer-content-section p {
    height: 48px;
    margin-bottom: 32px;
  }
  .footer-piller {
    display: flex;
  }
  .footer-com {
    display: block;
  }
  footer {
    height: 48px;
  }
  footer > * {
    grid-gap: 0;
    gap: 0;
    flex-direction: row;
    text-align: left;
  }
}

@media only screen and (min-width: 900px) {
  .footer-social-piller {
    display: flex;
  }
}

.modal-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  animation: fade-in 0.2s;
  display: flex !important;
  z-index: 150;
}

.modal-container {
  border-radius: 20px;
  padding: 16px 0;
  background: var(--foreground-color);
  box-shadow: var(--modal-shadow);
  animation: drop-down 0.2s;
  max-width: 320px;
  margin: 8px;
  box-sizing: border-box;
  width: Calc(100% - 16px);
  /* background: #fff; */
  animation: fade-in-up 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
}

/* .modal-wrapper {
  background: #fff;
  animation: fade-in-up 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
} */

/* Font----------- */

a {
  color: inherit;
  text-decoration: none;
}

.App {
  text-align: center;
  width: 100%;
  position: relative;
  overflow: hidden;
}
.App div {
  box-sizing: border-box;
}

.content-wrapper {
  margin-top: 48px;
}

ul {
  margin: 0;
  padding: 0;
}
input {
  box-shadow: inset 0px 0px 0px 0px red;
}

button {
  background: none;
  font-weight: 700;
  font-size: 16px;
  border: none;
  padding: 10px 25px;
  height: 40px;
  border-radius: 100px;
  cursor: pointer;
  outline: inherit;
  transition: 0.15s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 12px;
  gap: 12px;
  color: var(--highlight);
  white-space: nowrap;
}
button svg {
  height: 24px;
  width: 24px;
  margin-right: -8px;
}
button:hover {
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.09),
    0px 15px 25px rgba(0, 0, 0, 0.12);
}

button.line {
  border: 2px solid var(--highlight);
  color: var(--highlight);
  background: none;
}
button.line:hover {
  box-shadow: none;
  color: var(--tertiary-background);
  background: var(--highlight);
  transform: scale(1.03);
}
button.primary {
  background: var(--highlight);
  color: var(--tertiary-background);
}

.secondary {
  background: var(--primary-background);
  color: var(--primary-font);
  box-shadow: 0px 1px 2px rgb(0 0 0 / 10%), 0px 4px 4px rgb(0 0 0 / 5%);
}
.secondary:hover,
button.primary:hover {
  transform: scale(1.03);
}

button.fog {
  color: var(--highlight);
  border: 1px solid rgba(255, 255, 255, 0);
}
button.fog:hover {
  background: rgba(255, 255, 255, 0.25);
  box-shadow: none;
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  border: 1px solid rgba(255, 255, 255, 0.18);
}
button.icon-button {
  width: 32px !important;
  min-width: 32px !important;
  height: 32px !important;
  padding: 0 !important;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
button.icon-button svg {
  width: 24px !important;
  height: 24px !important;
  margin: 0;
  display: block;
}

section,
footer {
  display: flex;
  justify-content: center;
  /* overflow-x: hidden; */
}

footer > *:last-child,
section > *:last-child {
  width: 100%;
  position: relative;
  z-index: 10;
  padding: 24px 16px;
  box-sizing: border-box;
}

input {
  height: 48px;
  padding: 0 16px;
  border-radius: 12px;
  box-sizing: border-box;
  margin-bottom: 4px;
  outline: none;
  border: none;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 4px 4px rgba(0, 0, 0, 0.05);
}
input:focus {
  outline: none;
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
  font-size: 16px;
}

.primary-section {
  background: var(--primary-background);
}

.secondary-section {
  background: var(--secondary-background);
}

/* common classes------------------ */
.page-section {
  padding-top: 64px;
  text-align: left;
}

.glass-back {
  /* background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.3);
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  border: 1px solid rgba(255, 255, 255, 0.18); */
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.18);
}

/* Animations------------------ */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes drop-right {
  0% {
    transform: translatex(-16px);
  }
  100% {
    transform: translatey(0);
  }
}
@keyframes fade-in-up {
  0% {
    opacity: 0;
    transform: translatey(80px);
  }
  100% {
    opacity: 1;
    transform: translatey(0);
  }
}
@keyframes fade-in-up-sml {
  0% {
    opacity: 0;
    transform: translatey(24px);
  }
  100% {
    opacity: 1;
    transform: translatey(0);
  }
}

@keyframes fade-in-down {
  0% {
    opacity: 0;
    transform: translatey(-80px);
  }
  100% {
    opacity: 1;
    transform: translatey(0);
  }
}
@keyframes fade-in-right {
  0% {
    opacity: 0;
    transform: translatex(-24px);
  }
  100% {
    opacity: 1;
    transform: translatey(0);
  }
}
@keyframes fade-in-left {
  0% {
    opacity: 0;
    transform: translatex(24px);
  }
  100% {
    opacity: 1;
    transform: translatey(0);
  }
}

.float-in-place {
  animation: float 7s ease-in-out infinite;
}
@keyframes float {
  0% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px) rotate(-8deg);
  }
  50% {
    box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
    transform: translatey(-8px) rotate(8deg);
  }

  100% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px) rotate(-8deg);
  }
}

/* Flexbox--------------------- */
.flex-cent {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-just-cent {
  display: flex;
  justify-content: center;
}
.flex-just-btw {
  display: flex;
  justify-content: space-between;
}
.flex-just-rit {
  display: flex;
  justify-content: flex-end;
}
.flex-just-lft {
  display: flex;
  justify-content: flex-start;
}
.flex-align-cent {
  display: flex;
  align-items: center;
}
.flex-align-top {
  display: flex;
  align-items: flex-start;
}
.flex-col {
  display: flex;
  flex-direction: column;
}

/* mobile------------------------------- */
.desktop-only {
  display: none;
}

.mobile-only {
  display: flex;
}

/* Tablet---- */
@media only screen and (min-width: 600px) {
  .content-wrapper {
    margin-top: 80px;
  }
  footer > *:last-child,
  section > *:last-child {
    max-width: 800px;
    padding: 36px 16px;
  }

  /* mobile------------------------------- */
  .desktop-only {
    display: flex;
  }

  .mobile-only {
    display: none;
  }
}
/* Desktop---- */
@media only screen and (min-width: 900px) {
  footer > *:last-child,
  section > *:last-child {
    max-width: 1088px;
    padding: 64px 36px;
  }
  p {
    font-size: 18px;
    line-height: 30px;
  }
  input[type="color"],
  input[type="date"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="email"],
  input[type="month"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  input[type="time"],
  input[type="url"],
  input[type="week"],
  select:focus,
  textarea {
    font-size: 18px;
  }
}
@media only screen and (min-width: 1200px) {
  footer > *:last-child,
  section > *:last-child {
    max-width: 1272px;
    padding: 36px 16px;
  }
  button {
    height: 48px;
  }
  button.header-button {
    height: 40px;
  }
}

.background-video {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  min-height: 100%;
}

:root {
  --highlight: #18223f; /*black*/

  --primary-color: #18223f; /*pink*/
  --primary-color-light: #18223f; /*pink-light*/
  --secondary-color: #18223f; /*green*/
  --secondary-color-light: #18223f; /*green-light*/
  --tertiary-color: #18223f; /*blue*/
  --tertiary-color-light: #18223f; /*blue -light*/

  --primary-font: #18223f;
  --secondary-font: #18223f;
  --tertiary-font: #18223f;
  --disabled-font-color: rgba(24, 33, 62, 0.5);

  --primary-background: #fff;
  --secondary-background: #fff;
  --tertiary-background: #fff;

  --line-color: rgba(24, 33, 62, 0.1);
}

/* @import url("https://rsms.me/inter/inter.css");
html {
  font-family: "Inter", sans-serif;
} */
html,
body {
  font-family: "Roboto", sans-serif;
}

h1 {
  font-weight: 900;
  font-size: 38px;
  line-height: 1.05;
  margin: 0;
}
h2 {
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 1.45;
  margin: 0;
}
h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 1.3;
  margin: 0;
}
h4 {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.45;
  margin: 0;
}
h5 {
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 1.5;
  margin: 0;
}
h6 {
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
}
p {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.6;
  margin: 0;
}

a {
  font-weight: 400;
}

@media screen and (min-width: 650px) {
  h1 {
    font-size: 56px;
  }
  h2,
  h4 {
    font-size: 24px;
  }
  h3 {
    font-size: 40px;
  }
  h5,
  h6 {
    font-size: 20px;
  }
}
@media screen and (min-width: 1200px) {
  h1 {
    font-size: 64px;
  }
  h2,
  h4 {
    font-size: 26px;
  }
  h3 {
    font-size: 48px;
  }

  h5,
  h6 {
    font-size: 22px;
  }
}

@media screen and (min-width: 1700px) {
  h1 {
    font-size: 80px;
  }
  h2,
  h4 {
    font-size: 32px;
  }
  h3 {
    font-size: 56px;
  }

  h5,
  h6 {
    font-size: 24px;
  }
  p {
    font-size: 20px;
  }
}


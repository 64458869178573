@media only screen and (min-width: 900px) {
  .account-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
    height: 100%;
  }
}
@media only screen and (min-width: 1200px) {
  .account-wrapper {
    grid-template-columns: 2fr 1fr;
    grid-gap: 32px;
  }
}

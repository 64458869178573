.chart-container {
  padding: 8px;
  box-sizing: border-box;
  border-radius: 20px;
  min-height: 30vh;
  /* grid-gap: 16px; */
  grid-gap: 8px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, 1fr);
}
.chart-container .chart-wrapper {
  grid-row: 1/3;
}
.chart-items-container {
  display: grid;
  /* grid-gap: 16px; */
  grid-gap: 8px;
  grid-template-columns: repeat(3, 1fr);
}

@media only screen and (min-width: 600px) {
  .chart-container {
    grid-template-rows: 1fr 1fr 120px;
  }
  .chart-items-container {
    display: flex;
    align-items: center;
  }
}

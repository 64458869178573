.modal-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  animation: fade-in 0.2s;
  display: flex !important;
  z-index: 150;
}

.modal-container {
  border-radius: 20px;
  padding: 16px 0;
  background: var(--foreground-color);
  box-shadow: var(--modal-shadow);
  animation: drop-down 0.2s;
  max-width: 320px;
  margin: 8px;
  box-sizing: border-box;
  width: Calc(100% - 16px);
  /* background: #fff; */
  animation: fade-in-up 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
}

/* .modal-wrapper {
  background: #fff;
  animation: fade-in-up 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
} */

:root {
  --highlight: #18223f; /*black*/

  --primary-color: #18223f; /*pink*/
  --primary-color-light: #18223f; /*pink-light*/
  --secondary-color: #18223f; /*green*/
  --secondary-color-light: #18223f; /*green-light*/
  --tertiary-color: #18223f; /*blue*/
  --tertiary-color-light: #18223f; /*blue -light*/

  --primary-font: #18223f;
  --secondary-font: #18223f;
  --tertiary-font: #18223f;
  --disabled-font-color: rgba(24, 33, 62, 0.5);

  --primary-background: #fff;
  --secondary-background: #fff;
  --tertiary-background: #fff;

  --line-color: rgba(24, 33, 62, 0.1);
}

.gauge-container {
  position: relative;
  width: 100%;
  max-width: 64px;
}

.gauge-container h5 {
  position: absolute;
  text-align: center;
  width: 100%;
  top: 30%;
  color: var(--primary-font);
}
.gauge-container p {
  font-size: 13px;
  font-weight: 500;
  opacity: 0.3;
  text-align: center;
  width: 100%;
  margin: 0;
  position: absolute;
  bottom: -10px;
  color: var(--primary-font);
}

.gauge-container svg {
  position: relative;
  z-index: 9;
}

.marker-wrapper {
  position: absolute;
  top: 0;
  left: Calc(50% - 2px);
  height: 64px;
  margin-bottom: 70px;
  z-index: 10;
}

.marker-line {
  border-radius: 2px;
  margin-top: -6px;
  width: 4px;
  height: 16px;
  background: white;
  box-shadow: 0px 1px 2px rgb(0 0 0 / 10%), 0px 4px 4px rgb(0 0 0 / 5%);
}

.landing-logo {
  /* width: 80%; */
  width: 100%;
  max-width: 800px;
}

.landing-button-wrap {
  flex-wrap: wrap;
  margin-top: 32px;
  row-gap: 8px;
}

.landing-button-wrap button {
  height: 42px;
}
.landing-button-wrap > *:first-child {
  margin-right: 8px;
}

@media only screen and (min-width: 900px) {
  .landing-button-wrap {
    margin-top: 56px;
  }
  .landing-button-wrap button {
    height: 56px;
    padding: 10px 32px;
    font-size: 18px;
  }
  .landing-button-wrap > *:first-child {
    margin-right: 16px;
  }
}

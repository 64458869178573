.chart-item-wrapper {
  text-align: center;
  border-radius: 14px;
  aspect-ratio: 1;
  padding: 12px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 4px 4px rgba(0, 0, 0, 0.05);
  background: var(--primary-background);
  overflow: hidden;
}
/* Charts ---------------- */

/* .chart-item-chart-wrap {
  position: relative;
  aspect-ratio: 1;
  height: 100%;
  border-radius: 100%;
  background: #6fcf97;
  padding: 8px;
}

.chart-item-chart-inner {
  height: 100%;
  width: 100%;
  background: var(--primary-background);
  border-radius: 100%;
} */

/* .gauge-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 80px;
}
.gauge-wrapper svg {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
} */

/* ---------------- */
.chart-item-wrapper h2 {
  font-weight: 700;
  margin-bottom: -2px;
}
.chart-item-wrapper h6 {
  font-weight: 700;
  opacity: 0.3;
}

@media only screen and (min-width: 600px) {
  .chart-item-wrapper {
    height: 120px;
    width: 120px;
  }
}

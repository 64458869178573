.auth-modal-header {
  border-bottom: 1px solid var(--line-color);
}
.auth-modal-header h5 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 8px 16px 16px;
  box-sizing: border-box;
  cursor: pointer;
  color: var(--disabled-font-color);
}

.auth-header-selection {
  color: var(--highlight) !important;
}

.auth-modal-selection-line {
  margin: -4px 16px 0;
  position: relative;
  width: Calc(50% - 32px);
  transition: 0.2s ease-in-out;
  border-bottom: 4px solid var(--highlight);
}

.auth-modal-submit-wrapper button {
  width: 50%;
  margin-top: 12px;
}

.auth-modal-social-button {
  padding: 8px;
  border-radius: 10px;
  width: calc(50% - 8px);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.15s ease;
  cursor: pointer;
  font-weight: 400;
}
.auth-modal-social-button svg {
  margin-right: 8px;
}

.auth-password-strength-hint {
  font-size: 12px;
  color: var(--disabled-font-color);
  height: 0;
  box-sizing: border-box;
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.2s ease-in,
    height 0.15s cubic-bezier(0.34, 1.56, 0.64, 1);
}

/* opacity .5s cubic-bezier(.34,1.56,.64,1),transform .5s cubic-bezier(.34,1.56,.64,1),-webkit-transform .5s cubic-bezier(.34,1.56,.64,1); */

.auth-password-strength-hint-active {
  height: 48px;
  opacity: 1;
  padding-bottom: 8px;
}

.auth-password-strength-hint p {
  color: var(--primary-font);
}

.auth-password-check-note div {
  font-size: 14px;
  font-weight: 600;
  text-align: right;
  width: auto;
  pointer-events: none;
  color: rgb(249, 41, 83);
  padding: 0 12px 0 0;
  position: absolute;
  right: 0;
  height: 40px;
  display: flex;
  align-items: center;
}
.auth-password-check-note input {
  width: 100%;
}

.auth-error-message {
  font-size: 12px;
  color: rgb(249, 41, 83);
  padding-bottom: 8px;
}

.auth-forgot-botton {
  min-width: 80px;
}

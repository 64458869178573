header {
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  z-index: 101;
  position: fixed;
  display: flex;
  align-items: center;
  flex-direction: column;
  transform: translateY(0);
  transition: transform 0.2s ease-in-out, background 0.3s ease,
    box-shadow 0.4s ease-in-out;
  /* background: var(--tertiary-background); */
}

header.header-shadow {
  background: var(--primary-background);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.09), 0px 10px 20px rgba(0, 0, 0, 0.12);
}
header.header-hidden {
  transform: translateY(-110%);
}
header.mobile-menue-active {
  background: var(--tertiary-background);
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.14);
  border-radius: 0 0 20px 20px;
}

header > * {
  width: 100%;
  padding: 0 16px;
  min-height: 56px;
}

header ul {
  list-style-type: none;
}

header li {
  padding: 8px 24px;
  text-decoration: none;
  font-weight: 600;
  transition: 0.15s ease;
}
header li:hover {
  opacity: 0.5;
}

.header-logo svg {
  height: 24px;
}

.logo-no-text,
.extra-button,
.primary-button,
.header-nav-wrap {
  position: relative;
  display: none;
}
.logo-text {
  display: block;
}

#mobile-menu-button svg {
  width: 32px;
  height: 32px;
}

.mobile-menu-wrap {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: none;
  animation: fadeIn ease 0.2s;
}
.mobile-menu-wrap.mobile-menue-active {
  display: block;
}

.mobile-menu {
  display: none;
  box-sizing: border-box;
  width: 100%;
  padding: 8px 8px 16px;
  animation: fade-in-down ease 0.2s;
}
header.mobile-menue-active .mobile-menu {
  display: block;
}
.mobile-menu ul {
  margin: 0 !important;
  padding: 4px 0 !important;
  border-top: 1px solid rgba(185, 202, 212, 0.5);
  border-bottom: 1px solid rgba(185, 202, 212, 0.5);
}
.mobile-menu ul li {
  width: 100%;
  padding: 16px 30px;
  box-sizing: border-box;
  text-align: left;
}
.mobile-menu ul li a {
  text-decoration: none;
  color: var(--secondary-font-color);
  text-align: left;
  font-size: 18px;
  font-weight: 500;
  transition: 0.15s ease;
}

.header-foot a button {
  width: Calc(100% - 32px);
  font-size: 18px;
  height: 48px;
  margin: auto;
}

.header-foot {
  padding: 16px 0 0 !important;
  /* background: var(--highlight); */
}
.header-foot .footer-social-links > *:not(:last-child) {
  margin-right: 16px !important;
}
.header-foot a {
  /* padding: 8px !important;
  background: #b9cad4 !important;
  border-radius: 10px !important; */
  width: 100%;
  font-size: 18px;
  height: 48px;
  margin: auto;
}

.header-foot svg {
  height: 28px !important;
  width: 28px !important;
}

.header-tem {
  width: 100%;
}
@media only screen and (min-width: 600px) {
  .header-nav-wrap {
    position: relative;
  }
  .header-button-wrap > * {
    margin-left: 24px;
  }
}
@media only screen and (min-width: 700px) {
  header {
    flex-direction: row;
    justify-content: center;
  }
  .header-tem {
    width: 30%;
  }
  .mobile-menu {
    display: none;
  }
  .header-nav-wrap,
  .primary-button {
    display: flex;
  }

  #mobile-menu-button {
    display: none;
  }
  .logo-text {
    display: none;
  }
  .logo-no-text {
    display: block;
  }
  .header-nav-underline {
    position: absolute;
    bottom: 0;
    left: 0;
    transition: 0.2s ease-in-out;
    margin-bottom: -28px;
  }
  .header-nav-underline.active-tab {
    /* margin-bottom: -18px; */
    margin-bottom: 0;
  }

  .header-nav-select {
    /* width: 8px;
    height: 8px;
    background: var(--tertiary-color);
    border: 2px solid var(--tertiary-color);
    border-radius: 4px; */

    width: 100%;
    height: 6px;
    background: var(--tertiary-color-light);
    border-radius: 4px;

    /* width: Calc(100% + 20px);
    height: 6px;
    margin: 0 -10px;
    background: var(--tertiary-color);
    border-radius: 4px 4px 0 0; */
  }
}

@media only screen and (min-width: 900px) {
  header > * {
    min-height: 80px;
  }
  .header-logo svg {
    height: 24px;
  }

  .logo-text {
    display: block;
  }
  .logo-no-text {
    display: none;
  }
  .header-nav-underline.active-tab {
    margin-bottom: -2px;
  }
}

@media only screen and (min-width: 1200px) {
  header > * {
    padding: 0 32px;
  }
  .extra-button {
    display: flex;
  }
}

.chart-wrapper {
  /* border: 1px solid white; */
  /* border-radius: 14px; */
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

#chart {
  width: 100%;
  z-index: 9;
  margin: 12px 0 -12px;
}
.apexcharts-yaxis {
  display: block;
  margin-left: -8px;
}

.chart-buttons {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;
  height: 36px;
  overflow: visible;
}
.chart-buttons span {
  font-weight: 900;
  font-size: 26px;
  color: var(--highlight);
}
.chart-buttons .icon-button {
  margin-left: 6px;
}
span.chart-cents {
  font-size: 18px;
  opacity: 0.3;
  margin: 2px 0 0 2px;
  font-weight: 600;
}
span.chart-dollar {
  /* font-size: 18px; */
  opacity: 0.3;
  font-weight: 600;
  margin-right: 2px;
}
